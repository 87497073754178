// Packages
import { useContext } from "react";

// Iconography
import { MdLanguage } from "react-icons/md";

// Styles
import styles from "../styles/components/Navbar.module.css";

// Assets
import LogoTruncated from "../assets/img/logo_truncated.png";

// Context Providers
import { LocaleContext } from "../providers/LocaleContext";

export default function Navbar({
  scrollToWhoWeAre,
  scrollToWhatWeDo,
  scrollToWorkWithUs,
}) {
  const { language } = useContext(LocaleContext);

  return (
    <nav className={styles["navbar-wrapper"]}>
      <div className={styles["navbar"]}>
        <div className={styles["left"]}>
          <div className={styles["logo-truncated"]}>
            <img src={LogoTruncated} alt="panglot logo" />
          </div>
          <ul className={styles["links"]}>
            <li onClick={scrollToWhoWeAre} className={styles["link"]}>
              Who We Are
            </li>
            <li onClick={scrollToWhatWeDo} className={styles["link"]}>
              What We Do
            </li>
            <li onClick={scrollToWorkWithUs} className={styles["link"]}>
              Work With Us
            </li>
          </ul>
        </div>
        <div className={styles["locale-toggle"]}>
          <MdLanguage className={styles["icon"]} />
          <span>{language}</span>
        </div>
      </div>
    </nav>
  );
}
