// Packages
import { useContext, useRef } from "react";
import { Fade } from "react-awesome-reveal";

// Assets
import HeroImg from "../assets/img/hero_img.png";
import HeroImgMobile from "../assets/img/hero_img_mobile.png";
import FeatureImg from "../assets/img/feature_img_1.png";
import FeatureImg2 from "../assets/img/feature_img_2.png";
import FeatureImg3 from "../assets/img/feature_img_3.png";
import LogoFull from "../assets/img/logo_full.png";

// Styles
import styles from "../styles/pages/Home.module.css";

// Components
import Navbar from "../components/Navbar";

// Context Providers
import { LocaleContext } from "../providers/LocaleContext";

export default function Home() {
  const { locale } = useContext(LocaleContext);

  const whoWeAre = useRef();
  const whatWeDo = useRef();
  const workWithUs = useRef();

  const scrollToWhatWeDo = (event) => {
    event.preventDefault();
    const elmnt = whatWeDo;
    elmnt.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "start",
    });
  };

  const scrollToWhoWeAre = (event) => {
    event.preventDefault();
    const elmnt = whoWeAre;
    elmnt.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "start",
    });
  };

  const scrollToWorkWithUs = (event) => {
    event.preventDefault();
    const elmnt = workWithUs;
    elmnt.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "start",
    });
  };

  return (
    <>
      <Navbar
        scrollToWhatWeDo={scrollToWhatWeDo}
        scrollToWhoWeAre={scrollToWhoWeAre}
        scrollToWorkWithUs={scrollToWorkWithUs}
      />
      <main className={styles["container-wrapper"]}>
        <div className={styles["main-container"]}>
          {/*  */}

          {/* HERO / MASTHEAD / BANNER */}
          <section className={styles["hero-container"]}>
            <Fade>
              <div className={styles["hero-content"]}>
                <h1>{locale.header.headline}</h1>
                <button
                  onClick={scrollToWorkWithUs}
                  className={styles["primary-button"]}
                >
                  {locale.header.button}
                </button>
              </div>
              <img
                className={styles["hero-background-img"]}
                src={HeroImg}
                alt="an eclectic mosaic of images featuring peoples from all over the world"
              />
              <img
                className={styles["hero-background-img-mobile"]}
                src={HeroImgMobile}
                alt=""
              />
            </Fade>
          </section>
          {/*  */}

          {/* COMING SOON */}
          <Fade>
            <section className={styles["section-container"]}>
              <div className={styles["section-image-container"]}>
                <img src={FeatureImg} alt="a showcase of Panglot's iOS app" />
              </div>
              <div className={styles["section-content-container"]}>
                <h2>{locale.coming_soon.headline}</h2>
                <p>{locale.coming_soon.body}</p>
                <button
                  onClick={(e) => {
                    window.open(
                      "https://www.linkedin.com/company/panglot-labs/",
                      "_blank",
                      "noreferrer"
                    );
                    e.preventDefault();
                  }}
                  className={styles["primary-button"]}
                >
                  {locale.coming_soon.button}
                </button>
              </div>
            </section>
          </Fade>
          {/*  */}

          {/* WHO WE ARE */}
          <Fade>
            <section className={styles["section-container-reverse"]}>
              <div className={styles["section-content-container"]}>
                <h2 ref={whoWeAre}>{locale.who_we_are.headline}</h2>
                <p>{locale.who_we_are.body}</p>
              </div>
              <div className={styles["section-image-container"]}>
                <img src={FeatureImg2} alt="a showcase of Panglot's iOS app" />
              </div>
            </section>
          </Fade>
          {/*  */}

          {/* WHAT WE DO */}
          <Fade>
            <section className={styles["section-container"]}>
              <div ref={whatWeDo} className={styles["section-image-container"]}>
                <img src={FeatureImg3} alt="a showcase of Panglot's iOS app" />
              </div>
              <div className={styles["section-content-container"]}>
                <h2>{locale.what_we_do.headline}</h2>
                <p>{locale.what_we_do.body}</p>
              </div>
            </section>
          </Fade>
          {/*  */}

          {/* WORK WITH US */}
          <Fade>
            <section className={styles["callout-wrapper"]}>
              <div ref={workWithUs} className={styles["callout"]}>
                <div className={styles["callout-content"]}>
                  <h1>{locale.lets_work_together.headline}</h1>
                  <p>{locale.lets_work_together.body}</p>
                </div>
                <div className={styles["callout-cta"]}>
                  <button
                    onClick={(e) => {
                      window.location.href = "mailto:hello@panglotlabs.com";
                      e.preventDefault();
                    }}
                    className={styles["primary-button"]}
                  >
                    {locale.lets_work_together.button}
                  </button>
                </div>
              </div>
            </section>
          </Fade>
          {/*  */}

          {/* FOOTER */}
          <div className={styles["footer-wrapper"]}>
            <div className={styles["footer"]}>
              <div className={styles["company-brief"]}>
                <span>
                  <img src={LogoFull} alt="" />
                </span>
                <p>{locale.footer.body}</p>
              </div>
              <div className={styles["footer-bottom"]}>
                <div className={styles["copyright"]}>
                  <span>© 2024 Panglot Labs</span>
                </div>
                <div className={styles["local"]}>
                  Made with ♥ by Panglot Labs
                </div>
              </div>
            </div>
          </div>
          {/*  */}

          {/*  */}
        </div>
      </main>
    </>
  );
}
