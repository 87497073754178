// Packages
import { useState, useEffect } from "react";

// Local imports
import localizations from "./localizations.json";

// Styles
import "./App.css";

// Context Provider
import { LocaleContext } from "./providers/LocaleContext";

// Components
import Home from "./pages/Home";

function App() {
  const [locale, setLocale] = useState(localizations.en);
  const [language, setLanguage] = useState("English");

  useEffect(() => {
    const browserLanguage = navigator.language;
    if (browserLanguage) {
      if (browserLanguage.includes("zh")) {
        setLocale(localizations["zh-tw"]);
        setLanguage("中文");
      }
    } else {
      setLocale(localizations.en);
      setLanguage("English");
    }
  }, [locale]);

  return (
    <LocaleContext.Provider value={{ locale, language }}>
      <Home />
    </LocaleContext.Provider>
  );
}

export default App;
